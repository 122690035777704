<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <!-- 한눈에 보기 -->
        <c-card class="" title="한눈에 보기" height="min-height" :noMarginPadding="true">
          <template slot="card-button">
            <q-btn-group outline >
              <!-- '['+standardInfo.reportName +'] '+ '출력' -->
              <c-btn label="출력" icon="print" size="sm" @btnClicked="accidentPrint" />
            </q-btn-group>
          </template>
        </c-card>
        <div class="q-pa-md" :style="[{ height: tabHeight }, { 'overflow-y': 'auto' }]">
          <q-timeline class="moc-timeline" color="green-4">
            <q-timeline-entry
              v-for="(item, idx) in items"
              :key="idx"
              :heading="item.heading" :class="{ 'q-pb-xs': item.heading }"
              :icon="item.icon">
              <template v-if="item.title" v-slot:title>
                <b class="text-blue-14">[{{item.title}}]</b>
              </template>
              <template v-if="item.subtitle" v-slot:subtitle>
                {{item.subtitle}}
              </template>
              <q-slide-transition v-if="!item.heading">
                <div v-show="item.visible">
                  <component
                    :is="item.component"
                    :param="param"
                    @setAccidentName="setAccidentName"
                  />
                </div>
              </q-slide-transition>
            </q-timeline-entry>
          </q-timeline>
        </div>
        <c-dialog :param="popupOptions"></c-dialog>
      </div>
    </div>
  </div>
</template>

<script>

import selectConfig from '@/js/selectConfig';
export default {
  name: 'process-accident-report',
  props: {
    param: {
      type: Object,
      default: () => ({
        iimAccidentId: '',
        stepCd: '',
      }),
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      editable: true,
      searchUrl: '',
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      accidentName: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      let numHeight = this.height ? Number(this.$_.replace(this.height, 'px', '')) : 600;
      numHeight = numHeight - 60
      return numHeight + 'px';
    },
    items() {
      return [
        { title: '사고 및 사고자 정보', visible: true, component: () => import(`${'./timeline/accidentInfoTL.vue'}`), param: { disabled: true, } },
        { title: '원인 및 재발방지', visible: true, component: () => import(`${'./timeline/causePreventionTL.vue'}`), param: { disabled: true, } },
        { title: '피해정도 사진', visible: true, component: () => import(`${'./timeline/accidentPictureTL.vue'}`), param: { disabled: true, } },
      ]
    }
  },  
  methods: {
    init() {
      // role setting
      // url setting
      this.getUrl = selectConfig.sop.iim.accident.process.get.url;
      this.printUrl = selectConfig.sop.iim.accident.process.print.url;
      // code setting
      // list setting
    },
    accidentPrint() {
      let thisVue = this;
      this.$http.url = this.$format(this.printUrl, this.param.iimAccidentId);
      this.$http.type = 'GET';
      this.$http.request(
        _result => {
          let fileOrgNm = this.accidentName + '.xlsx';
          let blob = thisVue.$comm.base64ToBlob(_result.data);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileOrgNm);
          } else {
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileOrgNm;
            link.click();
          }
        }, () => { }
      );
    },
    setAccidentName(accidentName) {
      this.accidentName = accidentName;
    },
  }
};
</script>
